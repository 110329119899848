// src/pages/Feed.js
import React, { useState } from 'react';
import { Container, Typography, IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, ListItemSecondaryAction, Avatar, Box, Button } from '@mui/material';
import ReactPlayer from 'react-player';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import CommentIcon from '@mui/icons-material/Comment';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import SendIcon from '@mui/icons-material/Send';
import { useSwipeable } from 'react-swipeable';
import './Feed.css';

const videos = [
  { id: 1, url: 'https://www.w3schools.com/html/mov_bbb.mp4', title: 'Eerste Video', likes: 120, comments: [{ text: "Wat een geweldige video!", likes: 5, user: 'Jan', avatar: '' }, { text: "Dit is echt leerzaam!", likes: 3, user: 'Sanne', avatar: '' }] },
  { id: 2, url: 'https://www.w3schools.com/html/movie.mp4', title: 'Tweede Video', likes: 85, comments: [{ text: "Heel interessant!", likes: 7, user: 'Anna', avatar: '' }] },
  { id: 3, url: 'https://www.w3schools.com/html/mov_bbb.mp4', title: 'Derde Video', likes: 230, comments: [{ text: "Super gedaan!", likes: 10, user: 'Mark', avatar: '' }] },
];

const Feed = () => {
  const [currentVideo, setCurrentVideo] = useState(0);
  const [likes, setLikes] = useState(videos.map(() => false));
  const [saved, setSaved] = useState(videos.map(() => false));
  const [openComments, setOpenComments] = useState(false);
  const [newComment, setNewComment] = useState('');
  const [videoComments, setVideoComments] = useState(videos.map((video) => video.comments));

  const handleNextVideo = () => setCurrentVideo((prev) => (prev + 1) % videos.length);
  const handlePreviousVideo = () => setCurrentVideo((prev) => (prev - 1 + videos.length) % videos.length);

  const toggleLike = () => {
    const updatedLikes = [...likes];
    updatedLikes[currentVideo] = !updatedLikes[currentVideo];
    setLikes(updatedLikes);
  };

  const toggleSave = () => {
    const updatedSaved = [...saved];
    updatedSaved[currentVideo] = !updatedSaved[currentVideo];
    setSaved(updatedSaved);
  };

  const openCommentSection = () => setOpenComments(true);
  const closeCommentSection = () => setOpenComments(false);

  const handleAddComment = () => {
    if (newComment.trim()) {
      const updatedComments = [...videoComments];
      updatedComments[currentVideo].push({ text: newComment, likes: 0, user: 'Jij', avatar: '' });
      setVideoComments(updatedComments);
      setNewComment('');
    }
  };

  const toggleCommentLike = (index) => {
    const updatedComments = [...videoComments];
    updatedComments[currentVideo][index].likes += 1;
    setVideoComments(updatedComments);
  };

  // Swipe handlers configureren
  const swipeHandlers = useSwipeable({
    onSwipedUp: () => handleNextVideo(),
    onSwipedDown: () => handlePreviousVideo(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <Container
      {...swipeHandlers}
      style={{
        padding: 0,
        overflow: 'hidden',
        height: '100vh',
        width: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        backgroundColor: 'black',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <ReactPlayer
          url={videos[currentVideo].url}
          playing
          controls={false}
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: 0, left: 0 }}
          config={{
            file: {
              attributes: {
                style: {
                  width: '100%',
                  height: '100%',
                  objectFit: 'cover', // Zorg ervoor dat de video volledig in de container past
                },
              },
            },
          }}
        />
      </div>

      {/* Comments and Action Buttons */}
      <div className="video-overlay">
        <div className="action-buttons" style={{ position: 'absolute', right: '10px', bottom: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <IconButton onClick={toggleLike} color="secondary">
            {likes[currentVideo] ? <FavoriteIcon style={{ color: 'red' }} /> : <FavoriteBorderIcon style={{ color: 'white' }} />}
          </IconButton>
          <Typography style={{ color: 'white' }}>{videos[currentVideo].likes + (likes[currentVideo] ? 1 : 0)}</Typography>

          <IconButton onClick={toggleSave}>
            {saved[currentVideo] ? <BookmarkIcon style={{ color: '#FEC107' }} /> : <BookmarkBorderIcon style={{ color: 'white' }} />}
          </IconButton>

          <IconButton onClick={openCommentSection}>
            <CommentIcon style={{ color: 'white' }} />
          </IconButton>
          <Typography style={{ color: 'white' }}>{videoComments[currentVideo].length}</Typography>
        </div>

        <Typography variant="h5" className="video-title" style={{ position: 'absolute', bottom: '20px', left: '10px', color: 'white' }}>
          {videos[currentVideo].title}
        </Typography>
      </div>

      {/* Comments Dialog */}
      <Dialog open={openComments} onClose={closeCommentSection} fullWidth>
        <DialogTitle>Reacties</DialogTitle>
        <DialogContent style={{ padding: 0 }}>
          <Box style={{ maxHeight: '50vh', overflowY: 'auto', padding: '10px' }}>
            <List>
              {videoComments[currentVideo].map((comment, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <Avatar src={comment.avatar} alt={comment.user} />
                  <ListItemText
                    primary={
                      <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                        {comment.user}
                      </Typography>
                    }
                    secondary={comment.text}
                  />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" onClick={() => toggleCommentLike(index)}>
                      <ThumbUpIcon style={{ fontSize: 16 }} />
                    </IconButton>
                    <Typography variant="body2" style={{ marginLeft: '4px' }}>{comment.likes}</Typography>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center', padding: '10px' }}>
            <TextField
              variant="outlined"
              placeholder="Voeg een reactie toe..."
              fullWidth
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              size="small"
            />
            <IconButton color="primary" onClick={handleAddComment}>
              <SendIcon />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeCommentSection} color="secondary">
            Sluiten
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Feed;
