// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TopBar from './components/TopBar'; // Vervang Header door TopBar
import BottomNavBar from './components/BottomNavBar'; // Zorg ervoor dat je dit juist importeert
import Dashboard from './pages/Dashboard';
import Feed from './pages/Feed';
import AccountSettings from './pages/AccountSettings';
import Settings from './pages/Settings';
import SocialMediaConnections from './pages/SocialMediaConnections'; 

const App = () => {
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
        {/* TopBar altijd zichtbaar */}
        <TopBar />

        {/* Main content area: plaats de routes hier */}
        <div style={{ flex: 1, overflowY: 'auto' }}>
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/feed" element={<Feed />} />
            <Route path="/account-settings" element={<AccountSettings />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/social-media-connections" element={<SocialMediaConnections />} />
          </Routes>
        </div>

        {/* Bottom Navigation Bar altijd zichtbaar */}
        <BottomNavBar />
      </div>
    </Router>
  );
};

export default App;
