// src/pages/AccountSettings.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button, RadioGroup, FormControlLabel, Radio, Switch } from '@mui/material';

const AccountSettings = () => {
  // State voor gebruikersgegevens
  const [userData, setUserData] = useState({
    fullName: localStorage.getItem('fullName') || '',
    email: localStorage.getItem('email') || '',
    password: '', // We willen hier misschien geen wachtwoord opslaan in localStorage vanwege beveiligingsredenen
    phone: localStorage.getItem('phone') || '',
    role: localStorage.getItem('userRole') || 'creator',
    cameraAccess: JSON.parse(localStorage.getItem('cameraAccess')) || false,
  });

  // Effect om camera toegang te vragen als de toggle wordt ingeschakeld
  useEffect(() => {
    const requestCameraAccess = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ video: true });
        alert('Camera toegang verleend');
      } catch (err) {
        alert('Camera toegang geweigerd');
        handleChange({ target: { name: 'cameraAccess', value: false } });
      }
    };

    if (userData.cameraAccess) {
      requestCameraAccess();
    }
  }, [userData.cameraAccess]);

  // Functie om wijzigingen te verwerken
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    setUserData((prev) => ({ ...prev, [name]: newValue }));
  };

  // Functie om gegevens op te slaan in localStorage
  const handleSaveSettings = () => {
    localStorage.setItem('fullName', userData.fullName);
    localStorage.setItem('email', userData.email);
    localStorage.setItem('phone', userData.phone);
    localStorage.setItem('userRole', userData.role);
    localStorage.setItem('cameraAccess', JSON.stringify(userData.cameraAccess));
    alert('Instellingen zijn succesvol opgeslagen!');
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom style={{ color: '#0D6EFD', fontWeight: 'bold' }}>
        Account Instellingen
      </Typography>

      {/* Registratiegegevensvelden */}
      <TextField
        fullWidth
        label="Volledige naam"
        name="fullName"
        value={userData.fullName}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="E-mail"
        name="email"
        value={userData.email}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Telefoonnummer"
        name="phone"
        value={userData.phone}
        onChange={handleChange}
        margin="normal"
      />
      <TextField
        fullWidth
        label="Nieuw wachtwoord"
        name="password"
        type="password"
        value={userData.password}
        onChange={handleChange}
        margin="normal"
      />

      {/* Rol selectie */}
      <Typography variant="h6" style={{ marginTop: '20px' }}>
        Selecteer je rol:
      </Typography>
      <RadioGroup name="role" value={userData.role} onChange={handleChange}>
        <FormControlLabel value="creator" control={<Radio />} label="Creator" />
        <FormControlLabel value="user" control={<Radio />} label="Gebruiker/Bezoeker" />
      </RadioGroup>

      {/* Camera Toestemming Toggle */}
      <FormControlLabel
        control={
          <Switch
            checked={userData.cameraAccess}
            onChange={handleChange}
            name="cameraAccess"
            color="primary"
          />
        }
        label="Camera toegang"
      />

      <Button
        variant="contained"
        color="primary"
        style={{ marginTop: '20px' }}
        onClick={handleSaveSettings}
      >
        Instellingen Bijwerken
      </Button>
    </Container>
  );
};

export default AccountSettings;
