// src/pages/SocialMediaConnections.js
import React, { useState } from 'react';
import { Container, Typography, List, ListItem, ListItemText, ListItemSecondaryAction, Button } from '@mui/material';

const socialMediaPlatforms = [
  { id: 1, name: 'Facebook', isConnected: false },
  { id: 2, name: 'Instagram', isConnected: false },
  { id: 3, name: 'YouTube', isConnected: false },
  { id: 4, name: 'TikTok', isConnected: false },
  { id: 5, name: 'Twitter', isConnected: false },
];

const SocialMediaConnections = () => {
  const [platforms, setPlatforms] = useState(socialMediaPlatforms);

  const handleConnect = (platformId) => {
    const updatedPlatforms = platforms.map((platform) =>
      platform.id === platformId ? { ...platform, isConnected: !platform.isConnected } : platform
    );
    setPlatforms(updatedPlatforms);
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom style={{ color: '#0D6EFD', fontWeight: 'bold' }}>
        Social Media Koppelingen
      </Typography>

      <List>
        {platforms.map((platform) => (
          <ListItem key={platform.id} divider>
            <ListItemText
              primary={platform.name}
              secondary={platform.isConnected ? 'Gekoppeld' : 'Niet gekoppeld'}
            />
            <ListItemSecondaryAction>
              <Button
                variant="contained"
                color={platform.isConnected ? 'secondary' : 'primary'}
                onClick={() => handleConnect(platform.id)}
              >
                {platform.isConnected ? 'Ontkoppelen' : 'Koppelen'}
              </Button>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Container>
  );
};

export default SocialMediaConnections;
