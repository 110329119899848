// src/pages/Settings.js
import React, { useState, useEffect } from 'react';
import { Container, Typography, RadioGroup, FormControlLabel, Radio, Switch, Button } from '@mui/material';

const Settings = () => {
  // State voor camera toegang en gebruikersrol
  const [role, setRole] = useState(localStorage.getItem('userRole') || 'creator');
  const [cameraAccess, setCameraAccess] = useState(JSON.parse(localStorage.getItem('cameraAccess')) || false);

  useEffect(() => {
    if (cameraAccess) {
      const requestCameraAccess = async () => {
        try {
          await navigator.mediaDevices.getUserMedia({ video: true });
          alert('Camera toegang verleend');
        } catch (err) {
          alert('Camera toegang geweigerd');
          setCameraAccess(false);
          localStorage.setItem('cameraAccess', JSON.stringify(false));
        }
      };
      requestCameraAccess();
    }
  }, [cameraAccess]);

  const handleRoleChange = (event) => {
    setRole(event.target.value);
    localStorage.setItem('userRole', event.target.value);
  };

  const handleCameraToggle = (event) => {
    const access = event.target.checked;
    setCameraAccess(access);
    localStorage.setItem('cameraAccess', JSON.stringify(access));
  };

  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom style={{ color: '#0D6EFD', fontWeight: 'bold' }}>
        Instellingen
      </Typography>

      {/* Rol selectie */}
      <Typography variant="h6">Selecteer je rol:</Typography>
      <RadioGroup value={role} onChange={handleRoleChange}>
        <FormControlLabel value="creator" control={<Radio />} label="Creator" />
        <FormControlLabel value="user" control={<Radio />} label="Gebruiker/Bezoeker" />
      </RadioGroup>

      {/* Camera toegang */}
      <FormControlLabel
        control={
          <Switch
            checked={cameraAccess}
            onChange={handleCameraToggle}
            name="cameraAccess"
            color="primary"
          />
        }
        label="Camera toegang"
      />

      <Button variant="contained" color="primary" style={{ marginTop: '20px' }}>
        Instellingen Bijwerken
      </Button>
    </Container>
  );
};

export default Settings;
