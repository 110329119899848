// src/pages/Dashboard.js
import React from 'react';
import { Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import PeopleIcon from '@mui/icons-material/People';
import InsightsIcon from '@mui/icons-material/Insights';

const Dashboard = () => {
  return (
    <Container style={{ marginTop: '20px' }}>
      <Typography variant="h4" gutterBottom style={{ color: '#0D6EFD', fontWeight: 'bold' }}>
        Dashboard
      </Typography>
      <Grid container spacing={3}>
        {/* Kaart 1: Actieve Streams */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ borderRadius: '15px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <CardContent>
              <LiveTvIcon style={{ fontSize: '40px', color: '#0D6EFD' }} />
              <Typography variant="h6" style={{ marginTop: '10px' }}>
                Actieve Livestreams
              </Typography>
              <Typography variant="h4" style={{ fontWeight: 'bold' }}>3</Typography>
              <Button variant="contained" color="primary" style={{ marginTop: '10px' }}>
                Bekijk Streams
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Kaart 2: Aantal Kijkers */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ borderRadius: '15px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <CardContent>
              <PeopleIcon style={{ fontSize: '40px', color: '#FEC107' }} />
              <Typography variant="h6" style={{ marginTop: '10px' }}>
                Totale Kijkers
              </Typography>
              <Typography variant="h4" style={{ fontWeight: 'bold' }}>120</Typography>
              <Button variant="contained" color="primary" style={{ marginTop: '10px' }}>
                Bekijk Kijkers
              </Button>
            </CardContent>
          </Card>
        </Grid>

        {/* Kaart 3: Statistieken */}
        <Grid item xs={12} sm={6} md={4}>
          <Card sx={{ borderRadius: '15px', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <CardContent>
              <InsightsIcon style={{ fontSize: '40px', color: '#00A699' }} />
              <Typography variant="h6" style={{ marginTop: '10px' }}>
                Statistieken
              </Typography>
              <Typography variant="h4" style={{ fontWeight: 'bold' }}>5.8K</Typography>
              <Button variant="contained" color="primary" style={{ marginTop: '10px' }}>
                Bekijk Details
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;
