// src/components/BottomNavBar.js
import React, { useState, useEffect } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import LiveTvIcon from '@mui/icons-material/LiveTv';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import SettingsIcon from '@mui/icons-material/Settings';
import { useNavigate } from 'react-router-dom';

const BottomNavBar = () => {
  const [value, setValue] = useState(0);
  const [role, setRole] = useState(localStorage.getItem('userRole') || 'creator'); // Rol ophalen uit localStorage
  const navigate = useNavigate();

  useEffect(() => {
    const savedRole = localStorage.getItem('userRole') || 'creator';
    setRole(savedRole);
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) navigate('/dashboard');
    if (newValue === 1) role === 'creator' ? navigate('/live') : navigate('/feed');
    if (newValue === 2) navigate('/settings');
  };

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction label="Dashboard" icon={<DashboardIcon />} />
        {role === 'creator' ? (
          <BottomNavigationAction label="Live Stream" icon={<LiveTvIcon />} />
        ) : (
          <BottomNavigationAction label="Feed" icon={<VideoLibraryIcon />} />
        )}
        <BottomNavigationAction label="Instellingen" icon={<SettingsIcon />} />
      </BottomNavigation>
    </Paper>
  );
};

export default BottomNavBar;
